import {baseurl} from './index.js'

import axios from 'axios'

axios.defaults.baseURL = baseurl


export default {

	addnew( payload ){

		const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.post(  '/Rest/Portfolio/?action=addnew', URLparams.toString() )

	},

	Updateusers( payload ){

		const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.post(  '/Rest/Portfolio/?action=updateusers', URLparams.toString() )

	},

	getAll( payload ){

		return axios.get(  '/Rest/Portfolio?action=getall&context='+payload.orgkey+'&t=' + payload.token )

	},

	getOne( payload ){

		let includeparameters = ""

		if( payload.parameters ){
			includeparameters = '&includeparameters=yes'
		}

		let includevalues = ""

		if( payload.values ){
			includevalues = '&includeparametervalues=yes'
		}

		return axios.get(  '/Rest/Portfolio?action=getone&context='+payload.orgkey+'&portfolioid='+payload.pid+includeparameters+includevalues+'&t=' + payload.token )

	},

	getParameters( payload ){

		return axios.get(  '/Rest/Portfolio?action=getparameters&context='+payload.orgkey+'&portfolioid='+payload.pid+'&t=' + payload.token )

	},

	getboards( payload ){

		return axios.get(  '/Rest/Portfolio?action=getboards&context='+payload.orgkey+'&excludes='+payload.excludes+'&t=' + payload.token )

	},

	removeParameter( payload ){

		return axios.get(  '/Rest/Portfolio?action=removeparameter&context='+payload.orgkey+'&parameterid='+payload.parameterid+'&portfolioid='+payload.pid+'&t=' + payload.token )

	},
	undoremoveParameter( payload ){

		return axios.get(  '/Rest/Portfolio?action=undoremoveparameter&context='+payload.orgkey+'&parameterid='+payload.parameterid+'&portfolioid='+payload.pid+'&t=' + payload.token )

	},	

	getParameter( payload ){

		return axios.get(  '/Rest/Portfolio?action=getparameter&context='+payload.orgkey+'&parameterid='+payload.parameterid+'&portfolioid='+payload.pid+'&t=' + payload.token )

	},	

	addoption( payload ){

		const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.post(  '/Rest/Portfolio/?action=addoption', URLparams.toString() )

	},

	updateoption( payload ){

		const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.post(  '/Rest/Portfolio/?action=updateoption', URLparams.toString() )

	},

	removeoption( payload ){

		const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.post(  '/Rest/Portfolio/?action=removeoption', URLparams.toString() )

	},
		 

	addParameter( payload ){

		const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.post(  '/Rest/Portfolio/?action=addparameter', URLparams.toString() )

	},

	updateParameter( payload ){

		const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.post(  '/Rest/Portfolio/?action=updateparameter', URLparams.toString() )

	},

	addProject( payload ){

		const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.post(  '/Rest/Portfolio/?action=addprojecttoportfolio', URLparams.toString() )

	},

	removeProject( payload ){

		const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.post(  '/Rest/Portfolio/?action=removeprojectfromportfolio', URLparams.toString() )

	}, 

	addNewGroup( payload ){

		const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.post(  '/Rest/Portfolio/?action=addnewgroup', URLparams.toString() )

	}, 

	updateGroup( payload ){

		const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.post(  '/Rest/Portfolio/?action=updategroup', URLparams.toString() )

	},

	deleteGroup( payload ){

		const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.post(  '/Rest/Portfolio/?action=deletegroup', URLparams.toString() )

	},

	activeGroup( payload ){

		const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.post(  '/Rest/Portfolio/?action=activegroup', URLparams.toString() )

	}, 

	saveRows( payload ){

		const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.post(  '/Rest/Portfolio/?action=saverows', URLparams.toString() )
	},

	saveGroups( payload ){

		const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.post(  '/Rest/Portfolio/?action=savegroups', URLparams.toString() )
	},

	saveFormValue( payload ){

		const URLparams = new URLSearchParams(Object.entries(payload))
		return axios.post(  '/Rest/Portfolio/?action=saveformvalue', URLparams.toString() )

	},

	getFormvalue( payload ){

		return axios.get(  '/Rest/Portfolio?action=getformvalue&parameterid='+payload.parameterid+'&projectid='+payload.projectid+'&t=' + payload.token )

	},

	
}