<script type="text/javascript"></script>

<template>  

	<div>
			
		<TopBar :columnnumber = "2" hidedropdown simple>
			<template v-slot:title>
				Portfolios
			</template>
			
		</TopBar>

			<main class="main-wrapper cockpitview" style= "margin-top:21px;">

	<div class="cockpits">

		<h3 class="mt-2">My portfolios</h3>

		<div class="dashboarditemwrapper2">

			<div class="d-flex align-items-start flex-wrap">
				
				<router-link :to="'/'+orgkey+'/portfolio/'+item._id+'/detail'" v-for="(item,key) in portfolios" :key="key" class="card-block mr-2">
					<div class="card dashboarditem my-3">

						<div class="d-flex flex-row">
							<div class="accent"></div>

							<div class="align-self-center w-100">

								<div class="align-self-center  w-auto card-title">
									<span style="word-break: break-word;">{{ item.name }}</span>
								</div>
								<div class="activity row justify-content-md-center">
									<p data-toggle="tooltip" data-placement="bottom" title="Comments" class="col px-2">
										<span><i class="fas fa-comments" aria-hidden="true"></i>0</span>
									</p>
									<p data-toggle="tooltip" data-placement="bottom" title="Likes" class="col px-2">
										<span><i class="fas  fa-thumbs-up" aria-hidden="true"></i>0</span>
									</p>
									<p data-toggle="tooltip" data-placement="bottom" title="Dislikes" class="col px-2">
										<span><i class="fas  fa-thumbs-down" aria-hidden="true"></i>0</span>
									</p>
								</div>
							</div>

						</div>

					</div>
				</router-link>

				<div class="card dashboarditem my-3" >

				<form class="m-3" onsubmit="return false">
		        	<br>
		        	<input type="text" class="form-control" placeholder="Portfolio name" v-model="name" id="cockpitfromdashboardname" >
		        	<span class="text-danger" v-if="error!=''">&nbsp;{{error}}</span>

		        	<button type="submit" class="btn btn-primary mt-2" @click="addnewportfolio()">Add new</button>
		        </form>

				
			</div>

			</div>

			

		

		</div>

	</div>

</main></div>
</template> 

<style type="text/css">
	
	.portfolio{
		width: 250px;
		height: 125px;
	}

</style>

<script>

	import {TopBar} from '@/components/topbar'

	import Portfolioapi from '@/services/api/portfolio'

	import uuid from 'uuid'

	//const uuidv4 = require("uuid/v4")

	import { v4 as uuidv4 } from 'uuid'

	export default{
		name:'portfolio',
		props:{
		},

		data(){
			return{
				portfolios:[],
				name:"",
				error:"",
				find:0
			}
		},

		 mounted() {


		 	this.getallportfolios()
		 },

		watch:{
			
		},
		methods:{

			getallportfolios(){

				let self = this

				Portfolioapi.getAll( {orgkey:this.orgkey, token:this.token}).then( function( result ){

					if( result.data.ok && result.data.portfolios ){
						self.portfolios = result.data.portfolios
					}

				})
			},


			addnewportfolio(){

				let self = this

				if( this.name != "" ){

					Portfolioapi.addnew( { uuid:uuidv4(), name:this.name, context:this.orgkey, t:this.token } ).then( function( result ){
						self.name = ""
						self.getallportfolios()
					})
				}

				return false

				
			}
		},

		computed:{

			cockpitid(){
        		return this.$route.params.cid
     		},

			orgkey(){
		      return this.$route.params.orgname ? this.$route.params.orgname : null
		    },	

			token(){
				return this.$store.getters.token
			},

      		cockpit(){
				return this.$store.getters.GetCockpit
			},

		},

		components:{ TopBar }
	}
	</script>